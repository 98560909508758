import React from 'react';
import './ImageSlider.scss';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ImageSlider = ({ images }) => {
	const settings = {
		dots: false,
		fade: true,
		speed: 200,
		autoplay: true,
		autoplaySpeed: 3000,
		slidesToShow: 1,
		slidesToScroll: 1,
		infinite: true,
		loading: 'progressive',
		arrows: false,
	};
	return (
		<div className='image-slider__container'>
			<Slider {...settings} className='slider'>
				{images.map((image, index) => {
					return (
						<div key={index} className='image-slider__image-container'>
							<img
								src={image}
								alt=''
								className='image-slider__image'
								loading='lazy'
							/>
						</div>
					);
				})}
			</Slider>
		</div>
	);
};

export default ImageSlider;
