import { useEffect, useState } from 'react';
import './App.scss';
import AOS from 'aos';
import 'aos/dist/aos.css';

// Assets
import img1 from './assets/images/1.jpeg';
import img2 from './assets/images/2.jpeg';
import img3 from './assets/images/3.jpeg';
import logo from './assets/logo.png';
import phoneIcon from './assets/phoneIcon.svg';
import locationIcon from './assets/locationIcon.svg';

// Components
import HeroSection from './components/HeroSection/HeroSection';
import ImageSlider from './components/ImageSlider/ImageSlider';
import PhotoGrid from './components/PhotoGrid/PhotoGrid';
import Footer from './components/Footer/Footer';

function importAll(r) {
	return r.keys().map(r);
}
const brandLogos = importAll(
	require.context('./assets/brandLogo', false, /\.(png|jpeg|svg|JPG)$/)
);
const gallery = importAll(
	require.context('./assets/gallery', false, /\.(png|jpeg|svg|jpg|JPG)$/)
);

function App() {
	const images = [img1, img2, img3];
	const aosSettings = {
		'data-aos': 'zoom-in-up',
		'data-aos-offset': '100',
		'data-aos-delay': '50',
		'data-aos-duration': '700',
		'data-aos-easing': 'ease-in-out',
		'data-aos-mirror': 'true',
		'data-aos-once': 'false',
	};
	const [onLoad, setOnLoad] = useState(false);

	useEffect(() => {
		setOnLoad(true);
		AOS.init();
	}, []);

	return (
		<div className='app'>
			<div
				className='top'
				style={!onLoad ? { transform: 'translateY(-200px)' } : {}}
			>
				<HeroSection logo={logo} />
				<ImageSlider images={images} />
			</div>
			<div className='sections'>
				<section>
					<h3>Partners</h3>
					<div className='brands'>
						{brandLogos.map((image, index) => (
							<img
								src={image}
								key={index}
								{...{
									...aosSettings,
									...(window.innerWidth > 764
										? {
												'data-aos-delay': index * 100,
										  }
										: {}),
								}}
								alt={'logo' + index}
							/>
						))}
					</div>
				</section>
				<section>
					<h3>Gallery</h3>
					<div className='gallery'>
						<PhotoGrid images={gallery} />
					</div>
				</section>
				<section>
					<h3>Contact Us</h3>
					<div className='contact' {...aosSettings}>
						<div className='contact-details'>
							<div className='detail'>
								<img src={phoneIcon} alt='phone' />
								<div className='detailText'>
									+91 8859008686 <br /> +91 7409082110
								</div>
							</div>
							<div className='detail'>
								<img src={locationIcon} alt='location' />
								<div className='detailText'>
									Lovey Electronics, Railway Bazaar Rd <br /> Banbhoolpura,
									Haldwani, Uttarakhand
								</div>
							</div>
						</div>
						<iframe
							src='https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3110.712851525292!2d79.53142614488546!3d29.214307498884555!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjnCsDEyJzUxLjUiTiA3OcKwMzEnNTMuMSJF!5e0!3m2!1sen!2sin!4v1680691019516!5m2!1sen!2sin'
							width='600'
							height='450'
							style={{ border: 0 }}
							allowfullscreen=''
							loading='lazy'
							referrerpolicy='no-referrer-when-downgrade'
							className='map'
							name='map'
						></iframe>
					</div>
				</section>
			</div>
			<Footer />
		</div>
	);
}

export default App;
