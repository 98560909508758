import React, { useState, useEffect, useRef } from 'react';
import './GalleryCarouselPopup.scss';

//assets
import arrowRight from '../../assets/arrowRight.png';
import arrowLeft from '../../assets/arrowLeft.png';
import cross from '../../assets/cross.png';

const Gallery = ({
	open,
	setOpen,
	images,
	imageText = null,
	clickedImageIndex = 0,
	breakpoint = 900,
}) => {
	const [visibleIndex, setVisbileIndex] = useState(0);
	const imagesRef = useRef();
	const isMobile = window.innerWidth < breakpoint;

	const indices = images ? images : imageText;

	//animation on opening and closing
	const openAnimation = open
		? {
				top: '0vh',
				opacity: 1,
				transition: 'top 350ms ease-out, opacity 600ms ease-out',
		  }
		: {
				top: '100vh',
				opacity: 0,
				transition: 'top 350ms ease-out, opacity 600ms ease-out',
		  };
	const handleScroll = (e) => {
		if (!isMobile) return;
		const { scrollLeft } = e.target;
		const scrollIndex = Math.round(scrollLeft / window.innerWidth);
		setVisbileIndex(scrollIndex);
	};

	//set scroll value on clicking direction arrows
	useEffect(() => {
		if (isMobile) return;
		imagesRef.current.scrollLeft = window.innerWidth * visibleIndex;
	}, [visibleIndex]);

	useEffect(() => {
		// disable scroll on body when popup is open
		const bodyStyle = document.body.style;
		if (open) {
			bodyStyle.height = '100vh';
			bodyStyle.overflowY = 'hidden';
		} else {
			bodyStyle.height = 'auto';
			bodyStyle.overflowY = 'auto';
		}

		//disable swipe for desktop view
		imagesRef.current.style.pointerEvents = isMobile ? 'auto' : 'none';

		//scroll to the clicked image on open
		if (open) {
			setTimeout(() => {
				imagesRef.current.scrollLeft = window.innerWidth * clickedImageIndex;
				setVisbileIndex(clickedImageIndex);
			}, 200);
		}
	}, [open]);

	const close = (e) => {
		setOpen(false);
		e.stopPropagation();
	};

	return (
		<div className='__gallery' style={openAnimation} onClick={close}>
			<img id='gallery__cross' onClick={close} src={cross} />

			<div
				className='gallery__images'
				ref={imagesRef}
				onScroll={(e) => handleScroll(e)}
			>
				{images?.map((image, index) => {
					return (
						<div className='gallery__images-image' key={index}>
							<img src={image} alt='image' loading='lazy' />
						</div>
					);
				})}
				{imageText?.map(({ image, description }, index) => {
					return (
						<div className='image-description' key={index}>
							<img
								src={image}
								alt='image'
								// style={{ height: "530px" }}
								loading='lazy'
								className='image-description__image'
							/>
							<p className='description'>{description}</p>
						</div>
					);
				})}
			</div>

			{!isMobile && (
				<div className='gallery__arrows'>
					<img
						onClick={(e) => {
							e.stopPropagation();
							setVisbileIndex((prev) => {
								if (prev > 0) return prev - 1;
								return indices.length - 1;
							});
						}}
						src={arrowLeft}
					/>
					<img
						onClick={(e) => {
							e.stopPropagation();
							setVisbileIndex((prev) => {
								if (prev < indices.length - 1) return prev + 1;
								return 0;
							});
						}}
						src={arrowRight}
					/>
				</div>
			)}

			<div className='gallery__index'>
				<div className='text-sm-bold clr-white'>
					{visibleIndex + 1} of {images?.length} {imageText?.length}
				</div>
			</div>
		</div>
	);
};

export default Gallery;
