import React, { useState } from 'react';

//components
import Gallery from '../GalleryCarouselPopup/GalleryCarouselPopup';

//scss
import './PhotoGrid.scss';

const PhotoGrid = ({ images, className }, ...props) => {
	//---gallery carousel popup stuff
	const [openGallery, setOpenGallery] = useState(false);
	const [clickedImageIndex, setClickedImageIndex] = useState(0);

	const openGalleryAndSetIndex = (index) => {
		setOpenGallery(true);
		setClickedImageIndex(index);
	};
	return (
		<>
			<Gallery
				open={openGallery}
				setOpen={setOpenGallery}
				images={images}
				clickedImageIndex={clickedImageIndex}
			/>
			<div className={'photo-grid__wrapper ' + className} {...props}>
				{images.map((image, index) => {
					if (index > 2) return null;
					return (
						<img
							src={image}
							alt='home'
							key={index}
							className='photo'
							onClick={() => {
								openGalleryAndSetIndex(index);
							}}
							loading={'lazy'}
						/>
					);
				})}
				<button
					className='text-xsm clr-black'
					onClick={() => setOpenGallery((prev) => !prev)}
				>
					More Photos
				</button>
			</div>
		</>
	);
};

export default PhotoGrid;
