import React from 'react';
import './HeroSection.scss';

// Assets
import phoneIcon from '../../assets/phone.png';
import locationIcon from '../../assets/location-white.png';
import logo from '../../assets/logo.png';

// Components
import FormButton from '../FormButton/FormButton';

const HeroSection = () => {
	const openMap = () => {
		window.open(
			'https://www.google.com/maps/dir//29.2143056,79.5314167/@29.2143056,79.5314167,17z'
		);
	};
	return (
		<div className='hero-section'>
			<header>
				{/* <h2>Logo</h2> */}
				<img src={logo} alt='Lovey Electronics' className='logo' />
				<div className='info-container'>
					<div className='info'>
						<img src={phoneIcon} alt='' />
						<span>
							+91 8859008686 <br /> +91 7409082110
						</span>
					</div>
					<div className='info'>
						<img src={locationIcon} alt='' />
						<span>
							Railway Bazaar Rd, Banbhoolpura <br /> Haldwani, Uttarakhand
						</span>
					</div>
				</div>
			</header>
			<h1>Lovey Electronics</h1>
			<div className='slogan'>
				Sabse Kam <span className='highlight'>Daam</span>, Sabse Badiya{' '}
				<span className='highlight'>Kaam</span>
			</div>
			<div className='actions'>
				<FormButton transparent={false} onClick={openMap}>
					Find Us
				</FormButton>
			</div>
		</div>
	);
};

export default HeroSection;
