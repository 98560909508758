import './FormButton.scss';
import loader from '../../assets/spinning_wheel.svg';
import { useRef } from 'react';
import { useEffect } from 'react';

const FormButton = ({
	children,
	className = '',
	fontSize = '16px',
	height = '',
	onClick = () => {},
	disabled = false,
	loading = false,
	fullWidth = false,
	borderRadius = '',
	width = '',
	validationError = null,
	transparent = false,
	maxWidth = 'max-content',
	style = {},
	danger = false,
	triggerClickOnEnter = false,
}) => {
	const buttonRef = useRef(null);
	const triggerClick = (e) => {
		if (e.keyCode === 13 && triggerClickOnEnter) buttonRef.current.click();
	};
	useEffect(() => {
		window.addEventListener('keyup', triggerClick);

		return () => {
			window.removeEventListener('keyup', triggerClick);
		};
	}, []);

	return (
		<>
			{validationError && (
				<div className='form-button__error'>*{validationError.toString()}</div>
			)}
			<button
				ref={buttonRef}
				className={
					`form-button ${danger && 'danger'}  ${
						loading && 'form-button-loading'
					} ${className} ` + (transparent ? 'form-bottom__transparent' : '')
				}
				style={{
					fontSize: fontSize,
					height: height,
					maxWidth: fullWidth ? '100%' : maxWidth,
					borderRadius: borderRadius,
					width: width,
					backgroundColor: transparent ? 'transparent' : '',
					...style,
				}}
				onClick={(e) => {
					if (loading) return;
					onClick(e);
				}}
				disabled={disabled}
			>
				{loading ? (
					<img
						src={loader}
						height='16'
						width='16'
						alt='loading'
						className='form-button-loader'
					/>
				) : (
					children
				)}
			</button>
		</>
	);
};

export default FormButton;
